// components/SearchModal.tsx
"use client"
import React, { useState } from "react";
import { Modal, Input, List } from "antd";
import { Search, X } from "react-feather";
import { searchPageFilter } from "@/utils/seachPageFilter";
import styles from "./search-overlay.module.scss";
import Link from 'next/link';
import { pageRuData, pageEnData, pageCnData, placeholderEn, placeholderCh, placeholderRu } from "@/data/pageSearchData";
import { useLocale } from "next-intl";
import { searchItems } from '@/utils/apiSearch';
import { API_BASE_URL } from "@/config/AppConfig";
import {useSearchParams} from "next/navigation";

interface SearchListItem {
  id: number;
  searchName?: string;
  url: string;
  title: string;
}

interface SearchModalProps {
  isModalVisible: boolean;
  hideModal: () => void;
}

const SearchModal: React.FC<SearchModalProps> = ({
  isModalVisible,
  hideModal,
}) => {
  const locale = useLocale();
  let pageData = pageRuData;
  let placeholder = placeholderRu;
  if(locale === 'en') {pageData = pageEnData; placeholder = placeholderEn;}
  if(locale === 'ch') {pageData = pageCnData; placeholder = placeholderCh}
  const [searchResults, setSearchResults] = useState<SearchListItem[]>(pageData);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (searchTerm: string) => {
    if (!searchTerm.trim()) {
      setSearchResults(pageData);
      return;
    }
    setIsLoading(true);
    try {
      const locale = 'ru'; // Предполагая, что это работает внутри компонента
      const results = await searchItems(searchTerm, locale);
      const pageDataResults: SearchListItem[] = results.map(result => {
        return {
          id: result.id,
          title: result.title,
          url: '../' + result.url
        };
      });
      setSearchResults(pageDataResults);
    } catch (error) {
      console.error("Error during search:", error);
      // Обработка ошибки
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      wrapClassName={`${styles.search_modal} search-modal`}
      visible={isModalVisible}
      onOk={hideModal}
      onCancel={hideModal}
      footer={null}
      closeIcon={
        <span className={styles.close_icon} onClick={hideModal}>
          <X />
        </span>
      }
    >
      <Input.Search
        className={styles.input_search}
        inputMode="search"
        enterButton={<Search />}
        placeholder={placeholder}
        onChange={(e) => handleSearch(e.target.value)}
        loading={isLoading}
        style={{ width: "100%" }}
      />
      <List
        itemLayout="horizontal"
        dataSource={searchResults}
        renderItem={(item) => (
          <List.Item className={styles.list_item}>
            <List.Item.Meta title={<Link href={item.url}>{item.title}</Link>} />
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default SearchModal;
