import React, { useEffect, useState } from 'react';
import { Eye, EyeOff } from 'react-feather';

const AccessibilityToggle: React.FC = () => {
    const [isAccessibleMode, setAccessibleMode] = useState<boolean>(false);

    useEffect(() => {
        // Проверяем, если режим сохранён в локальном хранилище
        const savedMode = localStorage.getItem('accessibleMode') === 'true';
        if (savedMode) {
            setAccessibleMode(true);
            document.body.classList.add('accessibility-mode');
        } else {
            document.body.classList.remove('accessibility-mode');
        }
    }, []);

    const handleToggle = () => {
        const newMode = !isAccessibleMode;
        setAccessibleMode(newMode);
        document.body.classList.toggle('accessibility-mode', newMode);
        localStorage.setItem('accessibleMode', newMode.toString());
    };

    return (
        <div onClick={handleToggle} style={{ cursor: 'pointer' }}>
            {isAccessibleMode ? <EyeOff size={32} /> : <Eye size={32} />}
        </div>
    );
};

export default AccessibilityToggle;
