'use client'
import { Col, Dropdown, Flex, Select, Space } from 'antd'
import Image from 'next/image'
import Link from 'next/link'
import React, { useMemo } from 'react'
import {
    NavListType,
    mainNavigationItems,
} from './data/header.repository'
import { Eye } from 'react-feather'
import BaseContainer from '../base-container/BaseContainer'
import HeadingSearch from './HeadingSearch'
import styles from './header-desktop.module.scss'
import { LangOptionsType } from '@/components/common/header/data/header.types'
import { useLocale, useTranslations } from 'next-intl'
import { useUserState } from '@/store/user.store'
import ProfileDropdownMenu from './profile-dropdown'
import { getDictionary } from "@/dictionaries";
import AccessibilityToggle from '../accessibility-toggle/AccessibilityToggle'

type DesktopHeaderType = {
    cls?: string
    locales: LangOptionsType[]
    handleChange: (value: string) => void
}
const DesktopHeader: React.FC<DesktopHeaderType> = ({
    cls,
    locales,
    handleChange,
}) => {
    const locale = useLocale()
    // const dict = await getDictionary(locale)

    const t = useTranslations('Header')
    const [accessToken, role] = useUserState((state) => [
        state.accessToken,
        state.role,
    ])
    function generateDropdownItems(items: NavListType[]) {
        return items.map((item, i) => ({
            key: i,
            label: (
                <Link href={`/${locale}/${item.path}`}>{t(item.langKey)}</Link>
            ),
        }))
    }
    const menuItems = useMemo(() => {
        return mainNavigationItems.map((item) => ({
            ...item,
            label: t(item.langKey),
        }))
    }, [t])


    return (
        <BaseContainer>
            <Flex
                className={styles.desktop_header_wrapper}
                justify="space-between"
                align="center"
            >
                <Col span={2}>
                    <Link href={`/${locale}`}>
                        <Image
                            src={
                                cls === 'fixed'
                                    ? '/images/logo-dark.svg'
                                    : '/images/bluelogo.svg'
                            }
                            alt=""
                            width={60}
                            height={100}
                        />
                    </Link>
                </Col>
                <nav className={styles.nav}>
                    <ul>
                        {menuItems.map((navItem) => {
                            if (navItem.children) {
                                const drodownItems = generateDropdownItems(
                                    navItem.children,
                                )
                                const Icon = navItem.icon
                                return (
                                    <li key={navItem.path}>
                                        <Dropdown
                                            menu={{ items: drodownItems }}
                                        >
                                            <Space>
                                                <Link
                                                    href={`/${locale}/${navItem.path}`}
                                                >
                                                    {navItem.label}{' '}
                                                    {Icon && <Icon />}
                                                </Link>
                                            </Space>
                                        </Dropdown>
                                    </li>
                                )
                            }
                            return (
                                <li key={navItem.path}>
                                    <Link href={`/${locale}/${navItem.path}`}>
                                        {navItem.label}
                                    </Link>
                                </li>
                            )
                        })}
                        <li className={styles.icon}>
                            <HeadingSearch />
                        </li>
                    </ul>
                </nav>
                <div className={styles.navRight}>
                    <Flex gap={16} align="center">
                        <Select
                            bordered={false}
                            onChange={handleChange}
                            popupClassName={styles.popupSelect}
                            className={'navSelect'}
                            defaultValue={useLocale()}
                            dropdownStyle={{
                                color: 'var(--text-white)',
                            }}
                            options={locales}
                        />
                        <ProfileDropdownMenu
                            accessToken={accessToken}
                            locale={locale}
                            role={role}
                        />
                        <AccessibilityToggle />
                    </Flex>
                </div>
            </Flex>
        </BaseContainer>
    )
}

export default DesktopHeader
