'use client'
import { Col, Dropdown, Flex, Select, Space } from 'antd'
import Image from 'next/image'
import Link from 'next/link'
import React, { useEffect, useMemo, useState } from 'react'
import { ChevronDown, Eye, Menu, Search, User, X } from 'react-feather'
import { NavListType, mainNavigationItems } from './data/header.repository'
import { motion } from 'framer-motion'
import BaseContainer from '../base-container/BaseContainer'
import styles from './header-mobile.module.scss'
import { LangOptionsType } from '@/components/common/header/data/header.types'
import HeadingSearch from './HeadingSearch'
import { useLocale, useTranslations } from 'next-intl'
import AccessibilityToggle from '../accessibility-toggle/AccessibilityToggle'

type MobileHeaderType = {
    cls?: string
    locales: LangOptionsType[]
    handleChange: (value: string) => void
}
const MobileHeader: React.FC<MobileHeaderType> = ({
    cls,
    handleChange,
    locales,
}) => {
    const [navOpen, setNavOpen] = useState(false)
    const [subModalOpen, setSubModal] = useState(false)
    const locale = useLocale()
    const t = useTranslations('Header')

    function generateDropdownItems(items: NavListType[]) {
        return items.map((item, i) => ({
            key: i,
            label: (
                <Link href={`/${locale}/${item.path}`}>{t(item.langKey)}</Link>
            ),
        }))
    }
    const toggleModal = () => {
        setNavOpen((n) => !n)
    }
    useEffect(() => {
        document.body.style.overflow = navOpen ? 'hidden' : 'visible'
    }, [navOpen])

    const menuItems = useMemo(() => {
        return mainNavigationItems.map((item) => ({
            ...item,
            label: t(item.langKey),
        }))
    }, [t])

    const classNameFind = (cls?: string) => {
        if (navOpen || cls === 'fixed_black') return styles.select_purple
        else if (cls === 'fixed') return styles.select_white
        return styles.select_purple
    }
    return (
        <div
            className={`${styles.mobile_header}  ${navOpen ? styles.nav_open : ''}`}
        >
            <BaseContainer>
                <Flex align="center" justify="space-between">
                    <Col xs={6}>
                        <Link href={'/'} className={styles.logo}>
                            {navOpen ? (
                                <Image
                                    src={'/images/bluelogo.svg'}
                                    alt=""
                                    width={60}
                                    height={100}
                                />
                            ) : (
                                <Image
                                    src={
                                        (!navOpen ||
                                            cls === 'fixed' ||
                                            cls === 'fixed_black') &&
                                            cls !== 'black'
                                            ? '/images/logo-dark.svg'
                                            : '/images/bluelogo.svg'
                                    }
                                    alt=""
                                    width={60}
                                    height={100}
                                />
                            )}
                        </Link>
                    </Col>
                    <nav>
                        <ul>
                            <li className={styles.search_li}>
                                <HeadingSearch />
                            </li>
                            <AccessibilityToggle />
                            <li>
                                <Select
                                    bordered={false}
                                    onChange={handleChange}
                                    popupClassName={styles.popupSelect}
                                    className={`${classNameFind(cls)}`}
                                    defaultValue={locale}
                                    dropdownStyle={{
                                        color: 'var(--text-white)',
                                    }}
                                    options={locales}
                                />
                            </li>
                            <li
                                className={styles.burger_li}
                                onClick={toggleModal}
                            >
                                {navOpen ? <X /> : <Menu />}
                            </li>
                        </ul>
                    </nav>
                </Flex>
            </BaseContainer>
            {navOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: navOpen ? 1 : 0 }}
                    className={styles.mobile_menu}
                >
                    <nav className={styles.nav}>
                        <ul>
                            {menuItems.map((navItem) => {
                                if (navItem.children) {
                                    const drodownItems = generateDropdownItems(
                                        navItem.children,
                                    )
                                    const Icon = navItem.icon
                                    return (
                                        <li key={navItem.path}>
                                            <Link
                                                href={`/${locale}/${navItem.path}`}
                                                onClick={() =>
                                                    setSubModal((c) => !c)
                                                }
                                            >
                                                {navItem.label}{' '}
                                                {Icon && <Icon />}
                                            </Link>
                                            <motion.div
                                                initial={{
                                                    height: 0,
                                                    opacity: 0,
                                                    marginTop: 0,
                                                    display: 'none',
                                                }}
                                                animate={{
                                                    height: subModalOpen
                                                        ? 'auto'
                                                        : 0,
                                                    opacity: subModalOpen
                                                        ? 1
                                                        : 0,
                                                    marginTop: subModalOpen
                                                        ? '19px'
                                                        : 0,
                                                    display: subModalOpen
                                                        ? 'flex'
                                                        : 'none',
                                                }}
                                                exit={{
                                                    opacity: 0,
                                                    height: 0,
                                                    marginTop: 0,
                                                    display: 'none',
                                                }}
                                            >
                                                <ul className={styles.sub_nav}>
                                                    {drodownItems.map(
                                                        (item) => (
                                                            <li key={item.key}>
                                                                {item.label}
                                                            </li>
                                                        ),
                                                    )}
                                                </ul>
                                            </motion.div>
                                        </li>
                                    )
                                }
                                return (
                                    <li key={navItem.path}>
                                        <Link href={`/${locale}/${navItem.path}`}>
                                            {navItem.label}
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>
                </motion.div>
            )}
        </div>
    )
}

export default MobileHeader
